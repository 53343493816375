const config =  {
  apiKey: "AIzaSyBWUNAZZLZT1BYF0MA8n_bYnjw57e15PyA",
  authDomain: "fitsbydee-ce36c.firebaseapp.com",
  databaseURL: "https://fitsbydee-ce36c.firebaseio.com",
  projectId: "fitsbydee-ce36c",
  storageBucket: "fitsbydee-ce36c.appspot.com",
  messagingSenderId: "803890567766",
  appId: "1:803890567766:web:2a5a2748d410e42e3db9f7",
  measurementId: "G-8GWF21PV88"
}

export default config